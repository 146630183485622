* {
  box-sizing: border-box;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  background-color: #f1f5f9;
}

form {
  max-width: 500px;
  margin: 0 auto;
}

.error {
  color: #bf1650;
}

.error::before {
  display: inline;
  content: '⚠ ';
}

input,
textarea {
  display: block;
  box-sizing: border-box;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 10px 15px;
  margin-bottom: 1px;
  font-size: 14px;
}

input[type='button'] {
  background: #ffffff;
  color: black;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 3px;
  cursor: pointer;
}

button[type='submit'],
input[type='submit'] {
  background: #337ab7;
  color: white;
  border: none;
  margin-top: 20px;
  padding: 10px;
  font-size: 16px;
  font-weight: 100;
  letter-spacing: 3px;
  cursor: pointer;
}

button[type='submit']:hover,
input[type='submit']:hover {
  background: #3379b7a2;
}

button[type='submit']:active,
input[type='button']:active,
input[type='submit']:active {
  transition: 0.3s all;
  transform: translateY(3px);
  border: 1px solid transparent;
  opacity: 0.8;
}

input:disabled {
  opacity: 0.4;
}

button[type='submit'],
input[type='button'],
input[type='submit'] {
  -webkit-appearance: none;
}

.column-2 {
  float: left;
  width: 50%;
  padding: 5px;
}

.row:after {
  content: '';
  display: table;
  clear: both;
}

@media screen and (max-width: 600px) {
  .column-2 {
    width: 100%;
  }
}
